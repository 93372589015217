import React, { Component } from "react"
import Title from "../components/title"
import Input from "../components/input"
import Button from "../components/button"
import NewLayout from '../layout/newLayout'
import styles from "../styles/scss/pages/contactUs.module.scss"
import SEO from "../components/seo"
import Select from '../components/select'
import { Formik } from "formik"
import { isArray as _isArray, find as _find  } from "lodash"
import { signUp } from "../services/email"
import { getOccupations } from "../services/util";
import PageContents from '../components/pageContents'
import cn from "classnames"

export default class Index extends Component {

    attached = this.props.pageContext.page.attachments.filter((att) => {
        return att.slug === 'thank-you'
    })

    state = {
        pg: this.props.pageContext.page,
        thankyou: this.attached.length > 0 ? this.attached[0] : null,
        submitted: false,
        showModal: false,
        selectedOccupation: null,
    }

    occupations = getOccupations();

    render() {
        const { submitted, pg, thankyou } = this.state
        return (
            <NewLayout>
                {pg.seo.title && <h1 style={{ display: 'none' }}>{pg.seo.title}</h1>}
                <SEO page={pg} />
                <div className="d-flex flex-column flex-fill">
                    <div className={styles.container}>
                        {submitted
                            ?
                            <>
                                <PageContents content={thankyou.content} />
                            </>
                            :
                            <>
                                <div className={styles.padding}>
                                    <PageContents content={pg.content} />
                                </div>
                                <div className={styles.formItems}>
                                    <Formik
                                        initialValues={{
                                            firstName: "",
                                            lastName: "",
                                            email: "",
                                            phone: "",
                                            confirmEmail: "",
                                            occupation__pc: "",
                                        }}
                                        validate={values => {
                                            let errors = []
                                            //
                                            if (!values.firstName) {
                                                errors.push({ field: "firstName", message: "First Name is required" })
                                            }
                                            //
                                            if (!values.lastName) {
                                                errors.push({ field: "lastName", message: "Last Name is required" })
                                            }
                                            //
                                            if (!values.email) {
                                                errors.push({ field: "email", message: "Email is required" })
                                            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                                                errors.push({ field: "email", message: "Invalid email address" })
                                            }
                                            //
                                            if (!values.confirmEmail) {
                                                errors.push({ field: "confirmEmail", message: "Confirm Email is required" })
                                            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.confirmEmail)) {
                                                errors.push({ field: "confirmEmail", message: "Invalid confirmation email address" })
                                            } else if (values.email !== values.confirmEmail) {
                                                errors.push({ field: "confirmEmail", message: "Email and Confirm Email must match" })
                                            }
                                            if (values.occupation__pc === "") {
                                                errors.push({ field: "occupation__pc", message: "Occupation is required" })
                                            }
                                            return errors
                                        }}
                                        onSubmit={(values, { setSubmitting }) => {
                                            this.setState({
                                                showModal: true
                                            })

                                            const data = {
                                                firstName: values.firstName,
                                                lastName: values.lastName,
                                                email: values.email,
                                                phone: values.phone,
                                                occupation__pc: values.occupation__pc,
                                                industry: '',
                                                industrytime: '',
                                                birthday: '',
                                                userplus18yearsold: true,
                                                confirmation: true,
                                                optSubject: '',
                                                optMessage: '',
                                                signupFormID: 1828532,
                                                formIdentifier: 'signup'
                                            };


                                            signUp(data).then((result) => {
                                                setSubmitting(false)
                                                this.setState({
                                                    submitted: true,
                                                    showModal: false
                                                })
                                            }).catch((err) => {
                                                setSubmitting(false)
                                                this.setState({
                                                    showModal: false
                                                })
                                                alert("An error has occurred")
                                                console.log(err)
                                            })
                                        }}>
                                        {props => (
                                            <form className={styles.form} onSubmit={props.handleSubmit}>
                                                <div className={styles.padding}>
                                                    <div className={styles.row}>
                                                        <Input
                                                            name="firstName"
                                                            title="First Name"
                                                            placeholder="John"
                                                            className={styles.input}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={props.values.firstName}
                                                        />
                                                        <Input
                                                            name="lastName"
                                                            title="Last Name"
                                                            placeholder="Doe"
                                                            className={styles.input}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={props.values.lastName}
                                                        />
                                                    </div>
                                                    <div className={styles.row}>
                                                        <Input
                                                            name="email"
                                                            title="Email"
                                                            placeholder="jdoe@email.com"
                                                            className={styles.input}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={props.values.email}
                                                        />
                                                        <Input
                                                            name="confirmEmail"
                                                            title="Confirm Email"
                                                            placeholder="jdoe@email.com"
                                                            className={styles.input}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={props.values.confirmEmail}
                                                        />
                                                    </div>
                                                    <div className={styles.row}>
                                                        <Input
                                                            name="phone"
                                                            title="Phone Number"
                                                            phone="true"
                                                            placeholder="(000) 000-0000"
                                                            className={styles.input}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={props.values.phone}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={styles.padding}>
                                                    <div className={styles.row}>
                                                        <div className="col-sm-5" style={{ padding: '0px' }}>
                                                            <Select
                                                                title="Occupation*"
                                                                titleClassName={styles.selectTitle}
                                                                noMargin
                                                                className={styles.selectFull}
                                                                placeholder='Select'
                                                                values={this.occupations}
                                                                selected={props.values.occupation__pc}
                                                                onBlur={props.handleBlur}
                                                                value={props.values.occupation__pc}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        selectedState: _find(this.occupations, { value: e.value }),
                                                                    }, () => {
                                                                        props.setFieldValue('occupation__pc', e.value)
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={styles.feedback}>
                                                        <ul>
                                                            {_isArray(props.errors) && props.errors.filter((f) => { return props.touched[f.field] }).map((e) => {
                                                                return <li key={e.field}>{e.message}</li>
                                                            })}
                                                        </ul>
                                                    </div>
                                                    <div className={styles.row}>
                                                        <Button
                                                            text="Submit"
                                                            newBlue
                                                            submit
                                                            className={styles.input}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </>
                        }
                    </div>
                </div>
                {this.state.showModal &&
                    <div className={cn(styles.customModal)}>
                        <div className={cn(styles.modalContent)}>
                            <div>
                                <i className={cn('fa', 'fa-clock-o')}></i>
                            </div>
                            <div>
                                <Title yellow>Please wait...</Title>
                            </div>
                        </div>
                    </div>
                }
            </NewLayout>
        )
    }
}
